<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>
    <div class="ml-auto" style="padding-left: 17rem">
      <h3 class="font-weight-bolder">Kabayan Pilot Project</h3>
    </div>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler class="d-none d-lg-block mr-1" />
      <b-nav-item-dropdown
        class="dropdown-notification mr-25"
        menu-class="dropdown-menu-media"
        right
      >
        <template #button-content>
          <feather-icon
            :badge="notification.length"
            badge-classes="bg-danger"
            class="text-body"
            icon="BellIcon"
            size="21"
          />
        </template>
        <li class="dropdown-menu-header">
          <div class="dropdown-header d-flex">
            <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
            <b-badge pill variant="light-primary">
              {{ notification.length }} New
            </b-badge>
          </div>
        </li>
        <vue-perfect-scrollbar
          v-once
          :settings="perfectScrollbarSettings"
          class="scrollable-container media-list scroll-area"
          tagname="li"
        >
          <!-- System Notifications -->
          <b-link v-for="notif in notification" :key="notif.subtitle">
            <b-media>
              <template #aside>
                <b-avatar size="32" :variant="notif.type">
                  <feather-icon :icon="notif.icon" />
                </b-avatar>
              </template>
              <p class="media-heading">
                <span class="font-weight-bolder">
                  {{ notif.title }}
                </span>
              </p>
              <small class="notification-text">{{ notif.subtitle }}</small>
            </b-media>
          </b-link>
        </vue-perfect-scrollbar>
        <li class="dropdown-menu-footer">
          <b-button variant="primary" block>Read all notifications</b-button>
        </li>
      </b-nav-item-dropdown>
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user ml-1"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">Trial Admin</p>
            <span class="user-status">Admin</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/13-small.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" @click="getProfile()">
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="StopCircleIcon" class="mr-50" />
          <span>Start Tour</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BBadge,
  BMedia,
  BButton,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BBadge,
    BMedia,
    BButton,
    DarkToggler,
    VuePerfectScrollbar,
  },
  data() {
    return {
      notification: [
        {
          title: "Notification 1",
          subtitle: "Ini adalah Notification 1",
          icon: "HomeIcon",
        },
        {
          title: "Notification 2",
          subtitle: "Ini adalah Notification 2",
          icon: "UserIcon",
        },
      ],
    };
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push({ name: "login" });
    },
    getProfile(){
      this.$router.push('/profile')
    }
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };
    return { perfectScrollbarSettings };
  },
};
</script>
