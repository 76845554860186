<template>
  <date-picker
    v-model="filter_date"
    range
    type="datetime"
    popup-class="rounded mt-1"
    input-class="input bg-transparent form-control w-100 clickable"
    placeholder="25 Aug 2022 00:00 - 29 Aug 2022 23:59"
    confirm
    show-time-header
    time-title-format="HH : mm"
    range-separator=" - "
    confirm-text="Cari"
    format="DD MMM YYYY HH:mm"
  >
    <!-- CUSTOM ICON CALENDAR -->
    <template #icon-calendar>
      <div>
        <i
          class="bi bi-calendar icon"
          style="opacity: 0.5; margin-right: 6px"
        ></i>
      </div>
    </template>

    <!-- CUSTOM CALENDAR FOOTER -->
    <template #footer>
      <div class="row mt-1">
        <!-- LEFT ROW -->
        <div class="col-md-6">
          <!-- SHORTCUT HARI INI -->
          <div class="row mb-1 mx-auto shortcut">
            <b-badge
              pill
              variant="primary"
              class="badge-glow clickable w-100"
              @click="setToday"
            >
              Hari Ini
            </b-badge>
          </div>

          <!-- SHORTCUT KEMARIN -->
          <div class="row mb-1 mx-auto shortcut">
            <b-badge
              pill
              variant="primary"
              class="badge-glow clickable w-100"
              @click="setYesterday"
            >
              Kemarin
            </b-badge>
          </div>

          <!-- SHORTCUT 7 HARI TERAKHIR -->
          <div class="row mb-1 mx-auto shortcut">
            <b-badge
              pill
              variant="primary"
              class="badge-glow clickable w-100"
              @click="setOneWeekAgo"
            >
              7 Hari Terakhir
            </b-badge>
          </div>
        </div>

        <!-- RIGHT ROW -->
        <div class="col-md-6">
          <!-- SHORTCUT BULAN INI -->
          <div class="row mb-1 mx-auto shortcut">
            <b-badge
              pill
              variant="primary"
              class="badge-glow clickable w-100"
              @click="setThisMonth"
            >
              Bulan Ini
            </b-badge>
          </div>

          <!-- SHORTCUT 1 BULAN TERAKHIR -->
          <div class="row mb-1 mx-auto shortcut">
            <b-badge
              pill
              variant="primary"
              class="badge-glow clickable w-100"
              @click="setOneMonthAgo"
            >
              1 Bulan Terakhir
            </b-badge>
          </div>

          <!-- SHORTCUT 3 BULAN TERAKHIR -->
          <div class="row mx-auto shortcut">
            <b-badge
              pill
              variant="primary"
              class="badge-glow clickable w-100"
              @click="setThreeMonthAgo"
            >
              3 Bulan Terakhir
            </b-badge>
          </div>
        </div>
      </div>
    </template>
  </date-picker>
</template>
<script>
import { BButton, BBadge } from "bootstrap-vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "dateTimePicker",
  components: {
    BButton,
    BBadge,
    DatePicker,
  },

  data() {
    return {
      filter_date: [],
    };
  },

  mounted() {
    this.setOneWeekAgo();
  },

  methods: {
    // SET DATE TO THIS DAY
    setToday() {
      const start_date = new Date();
      const end_date = new Date();
      start_date.setHours(0);
      start_date.setMinutes(0);
      end_date.setHours(23);
      end_date.setMinutes(59);
      return (this.filter_date = [start_date, end_date]);
    },
    // SET DATE TO YESTERDAY
    setYesterday() {
      const start_date = new Date();
      const end_date = new Date();
      start_date.setDate(start_date.getDate() - 1);
      end_date.setDate(end_date.getDate() - 1);
      start_date.setHours(0);
      start_date.setMinutes(0);
      end_date.setHours(23);
      end_date.setMinutes(59);
      return (this.filter_date = [start_date, end_date]);
    },
    // SET DATE TO 7 DAYS AGO
    setOneWeekAgo() {
      const start_date = new Date();
      const end_date = new Date();
      start_date.setDate(start_date.getDate() - 7);
      start_date.setHours(0);
      start_date.setMinutes(0);
      end_date.setHours(23);
      end_date.setMinutes(59);
      return (this.filter_date = [start_date, end_date]);
    },
    // SET DATE TO THIS MONTH
    setThisMonth() {
      const start_date = new Date();
      const end_date = new Date();
      start_date.setMonth(start_date.getMonth());
      start_date.setDate(1);
      start_date.setHours(0);
      start_date.setMinutes(0);
      end_date.setHours(23);
      end_date.setMinutes(59);
      return (this.filter_date = [start_date, end_date]);
    },
    // SET DATE TO ONE MONTH AGO - THIS DAY
    setOneMonthAgo() {
      const start_date = new Date();
      const end_date = new Date();
      start_date.setMonth(start_date.getMonth() - 1);
      start_date.setHours(0);
      start_date.setMinutes(0);
      end_date.setHours(23);
      end_date.setMinutes(59);
      return (this.filter_date = [start_date, end_date]);
    },
    // SET DATE TO THREE MONTH AGO - THIS DAY
    setThreeMonthAgo() {
      const start_date = new Date();
      const end_date = new Date();
      start_date.setMonth(start_date.getMonth() - 3);
      start_date.setHours(0);
      start_date.setMinutes(0);
      end_date.setHours(23);
      end_date.setMinutes(59);
      return (this.filter_date = [start_date, end_date]);
    },
  },
};
</script>
<style>
.shortcut :hover {
  opacity: 0.5;
}
.input {
  border-color: #d8d6de !important;
  border-radius: 5px;
  border-style: solid !important;
  border-width: 1px;
  height: 42px;
}
.icon {
  color: #6e6b7b !important;
}
.mx-date-row .active {
  background-color: #7367f0 !important;
  border-radius: 50% !important;
}
.mx-date-row :hover {
  background-color: #b9b4f0 !important;
  color: white !important;
  border-radius: 50% !important;
}
.mx-date-row .in-range {
  width: 5px !important;
  border-radius: 50% !important;
}
.mx-date-row .hover-in-range {
  width: 5px !important;
  border-radius: 50% !important;
}
.mx-time-list .active {
  color: #7367f0 !important;
}
.mx-date-row .today {
  color: #00cfe8 !important;
}
.dark-layout * .mx-datepicker .mx-input-wrapper .input {
  color: #d0d2d6 !important;
}
.dark-layout * .mx-datepicker .mx-icon-calendar .icon {
  color: #d0d2d6 !important;
}
.dark-layout * .mx-datepicker .mx-icon-clear {
  color: #d0d2d6 !important;
}
</style>
