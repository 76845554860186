<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      Copyright @ {{ new Date().getFullYear() }}
      <span class="d-none d-sm-inline-block">Kurasi Media Nusantara</span>
    </span>

    <span class="float-md-right d-none d-md-block">v5.0.0 </span>
  </p>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
  },
};
</script>
