<template>
  <multiselect
    v-model="value"
    :options="topic_options"
    group-values="children"
    group-label="text"
    :group-select="true"
    placeholder="Pilih Topik"
    track-by="text"
    selectLabel=""
    selectedLabel=""
    selectGroupLabel=""
    deselectLabel=""
    deselectGroupLabel=""
    label="text"
    class="clickable"
  >
  </multiselect>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect,
  },

  data() {
    return {
      topic_options: [
        {
          text: "Semua Topik",
          children: [{ text: "Semua Topik", id: "all" }],
        },
        {
          text: "Media Konvensional",
          children: [
            { text: "Kemenkes", id: "1" },
            { text: "-- Kemenkes", id: "2" },
          ],
        },
        {
          text: "Media Sosial",
          children: [
            { text: "Sosial Budaya", id: "3" },
            { text: "-- Sosial Budaya", id: "4" },
          ],
        },
      ],
      value: { text: "Semua Topik", id: "all" },
    };
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__select {
  opacity: 0.5 !important;
  pointer-events: none !important;
}
.multiselect--active .multiselect__tags {
  border-radius: 5px !important;
  height: 42px !important;
}
.multiselect__tags {
  background-color: transparent !important;
  border-color: #d8d6de !important;
  border-radius: 5px !important;
  border-width: 1px !important;
}
.multiselect__single {
  background: none !important;
  font-size: 1rem !important;
  height: 24px !important;
}
.multiselect__input {
  background: none;
  font-size: 1rem !important;
}
.multiselect__element :hover {
  background-color: #b9b4f0 !important;
}
.multiselect__element {
  font-size: 1rem !important;
  background-color: #ffffff !important;
}
.multiselect__content {
  border-radius: 10px !important;
}
.multiselect__option--highlight {
  background-color: #7367f0 !important;
}
.multiselect__option--group {
  background-color: #e7e6e9 !important;
  pointer-events: none !important;
  font-size: 14px;
  font-weight: bolder;
  color: #73879c;
}
.multiselect__option--selected {
  background-color: #7367f0 !important;
  color: white !important;
}
.multiselect__placeholder {
  margin-left: 5px !important;
  color: #6e6b7b !important;
  opacity: 0.7;
}
.multiselect__content-wrapper {
  z-index: 3 !important;
  margin-top: 1rem !important;
  border-radius: 5px !important;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset !important;
}
.multiselect__content-wrapper::-webkit-scrollbar {
  display: none;
}
.dark-layout
  .h-100
  .vertical-layout
  .app-content
  .content-wrapper
  .content-header
  .row
  .col-md-6
  .multiselect
  .multiselect__tags
  .multiselect__single {
  color: #d0d2d6 !important;
}
.dark-layout
  .h-100
  .vertical-layout
  .app-content
  .content-wrapper
  .content-header
  .row
  .col-md-6
  .multiselect
  .multiselect__tags
  .multiselect__placeholder {
  color: #6e6b7b !important;
}
</style>
