<template>
  <div
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >
    <div class="row align-items-center">
      <div class="col-md-6 content-header-left mb-2 w-50">
        <div class="breadcrumbs-top">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $route.meta.pageTitle }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  :icon="$route.meta.pageIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </div>
      </div>
      <div class="col-md-6 d-flex">
        <selectTopik style="width:30%" class="mr-2 ml-auto card"/>
        <dateTimePicker class="card"/>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BButton,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
} from "bootstrap-vue";
import dateTimePicker from "@/components/usable/dateTimePicker.vue";
import selectTopik from "@/components/usable/selectTopik.vue";
export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BButton,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    dateTimePicker,
    selectTopik,
  },
};
</script>
